import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/softplaybig.png";
import { useNavigate } from "react-router-dom";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">SOFT PLAY</label>
        <div className="singleprd_contents">
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Soft Play
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
            Our soft play areas are designed for young children to play and explore in a safe and fun environment. They are made up of soft play equipment and structures, such as foam blocks, mats, tunnels, and bridges, that are specifically designed for children to climb, crawl, slide, and play on.
            </label>
            <label className="paragraph">
            GEM soft play playgrounds are suitable for shopping centers, family entertainment centers, and other indoor facilities that cater to families with young children.
            </label>{" "}
             
          </div>
        </div>
      </div>
    </>
  );
};
