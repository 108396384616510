import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import tr1 from "./images/tr1.png";
import tr2 from "./images/tr2.png";
import tr3 from "./images/tr3.png";
import tr4 from "./images/tr4.png";
import tr5 from "./images/tr5.png";
import tr6 from "./images/tr6.png";
import tr7 from "./images/tr7.png";
import tr8 from "./images/tr8.png";
import tr9 from "./images/tr9.png";
import { useNavigate } from "react-router-dom";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate=useNavigate()
  return (
    <>
      <div className="products">
        <label className="home_products_title">TRAMPOLINE</label>
        <label className="paragraph">
          GEM Trampoline parks are indoor recreational facilities that feature
          multiple interconnected trampolines, along with other activities such
          as foam pits, dodgeball courts, and obstacle courses. Trampoline parks
          have gained popularity in recent years: they are fun, very profitable
          and appeal to different age segments. They offer a fun and engaging
          way to exercise and burn off energy, and they can be a great option
          for birthday parties, group events, or just a fun day out with your
          guest’s friends or family. Trampoline parks usually offer a range of
          activities that cater to different skill levels and interests
        </label>
        <div className="trampoline_row">
          <div className="trampoline_row_single" onClick={e=>navigate('/PerformanceTrampoline')}>
            <img src={tr1} />
            <label>Performance Trampoline</label>
          </div>
          <div className="trampoline_row_single" onClick={e=>navigate('/MarioJump')}>
            <img src={tr2} />
            <label>Mario Jump</label>
          </div>
          <div className="trampoline_row_single" onClick={e=>navigate('/BasketBall')}>
            <img src={tr3} />
            <label>Basketball</label>
          </div>
        </div>
        <div className="trampoline_row">
          <div className="trampoline_row_single" onClick={e=>navigate('/ClimbingWall')}>
            <img src={tr4} />
            <label>Climbing Wall</label>
          </div>
          <div className="trampoline_row_single" onClick={e=>navigate('/FoamPit')}>
            <img src={tr5} />
            <label>Form Pit / Balance</label>
          </div>
          <div className="trampoline_row_single" onClick={e=>navigate('/Ninja')}>
            <img src={tr6} />
            <label>Ninja Course</label>
          </div>
        </div>
        <div className="trampoline_row">
          <div className="trampoline_row_single" onClick={e=>navigate('/RotatingBeam')}>
            <img src={tr7} />
            <label>Rotating Beam</label>
          </div>
          <div className="trampoline_row_single" onClick={e=>navigate('/Interactive')}>
            <img src={tr8} />
            <label>Interactive Trampoline</label>
          </div>
          <div className="trampoline_row_single" onClick={e=>navigate('/DodgeBall')}>
            <img src={tr9} />
            <label>Dodge Ball</label>
          </div>
        </div>
      </div>
    </>
  );
};
