import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/mariojump.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">MARIO JUMP</label>
        <div className="singleprd_contents">
         <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Mario Jump
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              Introducing Mario Jump - the latest sensation in the entertainment
              center industry! Mario Jump revolutionizes interactive gaming with
              its cutting-edge platform featuring original games suitable for
              both children and adults. Experience the thrill of jumping
              combined with technology, ensuring a safe and exhilarating
              adventure for all. This innovative game platform provides a
              significant boost to trampoline parks, offering a seamless
              integration option.
            </label>
            <label className="paragraph">
              Not only does Mario Jump deliver entertainment, but it also
              promotes socialization and physical activity among children.
              Encouraging exercise and fostering joy through jumping, it
              actively contributes to the mental and physical well-being of
              players. With a wide range of game choices available in the
              software game center, children are captivated by the multitude of
              engaging activities offered at various difficulty levels.
            </label>{" "}
            <label className="paragraph">
              Whether you have an indoor playground, trampoline park, or family
              entertainment center, Mario Jump is the ultimate interactive game
              solution. Elevate your establishment with this innovative addition
              that seamlessly integrates into existing trampoline parks.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
