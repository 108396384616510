import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/toddlerbig.png";
import { useNavigate } from "react-router-dom";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">TODDLER</label>
        <div className="singleprd_contents">
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Toddler
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              GEM toddler areas are designated spaces within an indoor or
              outdoor playground that is specifically designed for young
              children. It is a safe and secure space where toddlers can play
              and explore in a developmentally appropriate environment.
            </label>
            <label className="paragraph">
              Soft play equipment may include foam blocks, soft play climbers,
              and crawl tunnels.
            </label>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
