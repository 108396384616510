import React from "react";
import Header from "./Header";
import about from "./images/about.png";
import mission1 from "./images/mission1.png";
import vision from "./images/vision.png";
import Footer from "./Footer";
export default function AboutUs() {
  return (
    <>
      <Header scrolled={true} /> 
      <About />
      <Mission />
      <Footer/>
    </>
  );
}
const About = (_) => {
  return (
    <>
      <div className="about_about">
        <div className="about_about_left">
          <label className="blackheader">About us</label>
          <label className="paragraph">
            Global Entertainment Manufacturer is a premier manufacturer and
            supplier in the location-based entertainment industry. Providing
            quality, safety and innovative solutions to the trampoline park and
            indoor playground market, GEM has the skill to bring any soft play
            area to life – from design concepts to finished projects.
          </label>
          <label className="paragraph">
            Our engineering, design, and art departments are second to none,
            they will create the right project to suit your needs, thrill and
            excite your guests with the best entertainment experience. Whether
            it is an indoor playground or a trampoline park, we customize your
            project with colors and artwork that will make it a unique
            attraction.
          </label>
          <label className="paragraph">
            With focus on operational excellence and customer satisfaction, GEM
            is dedicated to providing high quality and durable playgrounds that
            stand the test of time. All of our products are made with the finest
            materials and constructed with the utmost attention to detail,
            ensuring that they are safe and well built. Whether you are looking
            for one playground equipment to improve your business or an entire
            new facility, make Global Entertainment Manufacturer your partner.
          </label>
        </div>
        <div className="about_about_right">
          <img src={about} />
        </div>
      </div>
    </>
  );
};
const Mission = (_) => {
  return (
    <>
      <div className="about_mission">
        <div className="about_mission_single">
          <img src={mission1}/>
          <label className="about_mission_single_title">OUR MISSION</label>
          <label className="about_mission_single_para">
            Safety and trustworthiness are the ground for all that we do. We are
            committed to delivering high quality products and services to our
            customers so they keep their business earning year after year with
            GEM.{" "}
          </label>
        </div>{" "}
        <div className="about_mission_single">
          <img src={vision}/>
          <label className="about_mission_single_title">OUR VISION</label>
          <label className="about_mission_single_para">
            We want to revolutionize the soft play industry by creating the most
            imaginative, interactive and safest playground environments in the
            world, and be your company of choice when it comes to a full-service
            manufacturing and design facility.
          </label>
        </div>
      </div>
    </>
  );
};
