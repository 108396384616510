import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/rotating.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">ROTATING BEAM</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Rotating Beam
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              A rotating beam trampoline is a type of trampoline that features a
              rotating beam or bar in the center of the jumping surface. This
              rotating beam challenges the users to perform unique and
              challenging acrobatic maneuvers in order not to be knocked down.
            </label>
            <label className="paragraph">
              The rotating beam of a rotating beam trampoline is made from a
              sturdy material, such as steel or aluminum, and is designed to
              rotate freely while users jump and perform acrobatic maneuvers.
              The rotating beam may be adjustable, allowing for different levels
              of difficulty and customization.
            </label>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
