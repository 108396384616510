import React from "react";
import { useNavigate } from "react-router-dom";

export default function SingleMenu() {
    const navigate =useNavigate()
  return (
    <>
      {" "}
      <div className="singleprd_menu">
        <label  onClick={e=>navigate('/PerformanceTrampoline')}>Performance Trampoline</label>
        <label onClick={e=>navigate('/MarioJump')}>Mario Jump</label>
        <label onClick={e=>navigate('/BasketBall')}>Basketball</label>
        <label onClick={e=>navigate('/ClimbingWall')}>Climbing Wall</label>
        <label onClick={e=>navigate('/FoamPit')}>Foam Pit</label>
        <label onClick={e=>navigate('/Ninja')}>Ninja Course</label>
        <label onClick={e=>navigate('/RotatingBeam')}>Rotating Beam</label>
        <label onClick={e=>navigate('/Interactive')}>Interactive Trampoline</label>
        <label onClick={e=>navigate('/DodgeBall')}>Dodge Ball</label>
      </div>
    </>
  );
}
