import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/performancetr.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">PERFORMANCE TRAMPOLINE</label>
        <div className="singleprd_contents">
         <SingleMenu />

          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Performance Trampoline
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              Our high-performance trampolines are larger, more durable, and
              more powerful than standard trampolines, allowing for higher jumps
              and more complex maneuvers. Performance trampolines are used in a
              variety of contexts, including gymnastics, acrobatics, and
              freestyle trampoline.
            </label>
            <label className="paragraph">
              One key feature of high-performance trampolines is their
              construction. They are built with a stronger frame, more robust
              springs, and a high-quality jumping mat that can withstand
              repeated use and high impact. They may also include additional
              safety features, such as padding around the frame and safety nets
              to prevent falls.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
