import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/ninja.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">NINJA COURSE</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Ninja Course
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              GEM ninja course trampolines include various obstacles and
              challenges like balance beams, rope swings, and hanging rings.
              They are popular between individuals who enjoy obstacle courses
              and other athletic challenges.
            </label>
            <label className="paragraph">
              The frame of a ninja course trampoline is made from durable and
              sturdy materials, such as steel or aluminum, to ensure it can
              withstand the impact and force of users jumping, bouncing, and
              completing the various obstacles.
            </label>{" "}
            <label className="paragraph">
              To ensure the safety of users, our ninja course trampolines
              regularly have padding around the frame and other areas to protect
              users from impact. They may also have safety nets or walls around
              the perimeter to prevent users from bouncing off the equipment or
              falling off the obstacles.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
