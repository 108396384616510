import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import softplay from './images/softplay.png'
import toddler from './images/toddler.png'
import lego from './images/lego.png'
import pg1 from './images/pg1.png'
import pg2 from './images/pg2.png' 
import pg3 from './images/pg3.png' 
import pg4 from './images/pg4.png' 
import pg5 from './images/pg5.png' 
import pg6 from './images/pg6.png' 
import pg7 from './images/pg7.png'  
import { useNavigate } from "react-router-dom";


export default function PlayGround() {
  return (
    <>
      <Header scrolled={true} />
      <PlayGroundc />
      <Footer />
    </>
  );
}
const PlayGroundc = (_) => {
  const navigate=useNavigate();
  return (
    <>
      <div className="playground">
        <label className="home_products_title">PLAYGROUND</label>
        <div className="playground_top">
          <div className="playground_top_left">
            <label>
              GEM Indoor playgrounds are spaces designed for children to play,
              explore and socialize in a safe and fun environment. They feature
              a variety of play structures such as slides, tunnels, climbing
              walls, ball pits, and obstacle courses that are specifically
              designed to promote physical activity, cognitive development, and
              social interaction.The size of the indoor playground can range
              from a small play area in a shopping center to a large indoor
              facility with multiple levels and activities.To ensure the safety
              of children, our kid’s playgrounds have safety features, such as
              soft flooring or padding, netting, and barriers to prevent falls
              or other injuries. The equipment and structures are also designed
              to be age-appropriate and safe for children to use.Our equipment
              also helps children develop gross motor skills, problem-solving
              abilities, and social skills through interactive play. This means
              you can feel good about providing a space that promotes healthy
              development for children.
            </label>
          </div>
          <div className="playground_right">
            <div onClick={e=>navigate('/SoftPlay')}>
              <img src={softplay}/>
              <label>Soft Play</label>
            </div>
            <div onClick={e=>navigate('/Toddler')}>
              <img src={toddler}/>
              <label>Toddler</label>
            </div>
          </div>
        </div>
        <div className="playground_middle"  onClick={e=>navigate('/LegoArea')}>
          <img src={lego}/>
          <label>Lego Area</label>
        </div>
        <div className="playground_bottom">
          <div className="playground_bottom_left">
            <img src={pg1}/>
            <div>
              <img src={pg2}/>
              <img src={pg3}/>
            </div>
          </div>
          <div className="playground_bottom_right"> 
            <img src={pg4}/>
            <img src={pg5}/>
            <div>
              <img src={pg6}/>
              <img src={pg7}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
