import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/dodge.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">DODGE BALL</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Dodge Ball
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              Dodgeball trampoline is a variation of dodgeball that is played on
              trampolines. It combines the traditional game of dodgeball with
              the added challenge and excitement of bouncing on a trampoline.
            </label>
            <label className="paragraph">
              GEM Dodgeball trampolines are larger than standard trampolines, as
              they need to accommodate multiple players, also the springs are
              stronger as they need to provide a higher bounce to allow for the
              unique maneuvers required in the game.
            </label>
            <label className="paragraph">
              To ensure the safety of players, our dodgeball trampolines have
              padding around the frame and other areas to protect players from
              impact.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
