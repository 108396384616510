import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Menu({ scrolled }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menu=useSelector(state=>state.menu)

  return (
    <>
      <div className={scrolled ? "page_menu page_menu_scroll" : "page_menu"}>
        <ul className={menu ? "mobilemenu" : "mobilemenuhide"}>
          <li
            onClick={(e) => {
              navigate("/");
              dispatch({type:'setMenu', payload: false})
            }}
          >
            HOME
          </li>
          <li
            onClick={(e) => {
              navigate("/About");
              dispatch({type:'setMenu', payload: false})
            }}
          >
            ABOUT
          </li>
          <li
            onClick={(e) => {
              navigate("/Service");
              dispatch({type:'setMenu', payload: false})
            }}
          >
            SERVICES
          </li>
          <li
            onClick={(e) => {
              navigate("/Products");
              dispatch({type:'setMenu', payload: false})
            }}
          >
            PRODUCTS
          </li>
          <li
            onClick={(e) => {
              navigate("/Contact");
              dispatch({type:'setMenu', payload: false})
            }}
          >
            CONTACT
          </li>
        </ul>
      </div>
    </>
  );
}
