import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {  legacy_createStore } from 'redux';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const initialState = {
  username:"",
  password:"",
  userid:"",
  menu: false
};

function todoReducer(prevState = initialState, action) {
  switch (action.type) {
    case 'setUserid': 
      return { ...prevState, userid: action.payload };
      break;
    case 'setPassword':
      return { ...prevState, password: action.payload };
      break;
      case 'setUsername':
        return { ...prevState, username: action.payload };
        break; 
        case 'setMenu':
          return { ...prevState, menu: action.payload };
          break; 
    default:
      return prevState;
  }
}

const persistedReducer = persistReducer(persistConfig, todoReducer);

export const store = legacy_createStore(persistedReducer);
export const persistor = persistStore(store);
