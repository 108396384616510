import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/foampit.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">FOAM PIT / BALANCE</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Foam Pit / Balance
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              A foam pit trampoline is a type of trampoline that includes a foam
              pit at the end of the jumping surface. This allows users to safely
              jump, flip, and perform acrobatic maneuvers into a soft-landing
              area.
            </label>
            <label className="paragraph">
              The size of the foam pit can vary, but it is large enough to
              accommodate multiple users at once. The bouncing surface of a foam
              pit trampoline is made from a strong and durable material, such as
              polypropylene, that is attached to the frame using springs. The
              bouncing surface is designed to provide a high-quality bounce
              while also being gentle on users' joints.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
