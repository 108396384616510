import React, { useEffect } from "react";
import Header from "./Header";
import banner from "./images/banner.png";
import product1 from "./images/product_1.png";
import product2 from "./images/product_2.png";
import product3 from "./images/product_3.png";
import product4 from "./images/product_4.png";
import one from "./images/one.png";
import two from "./images/two.png";
import three from "./images/three.png";
import four from "./images/four.png";
import five from "./images/five.png";
import six from "./images/si.png";
import seven from "./images/seven.png";
import eight from "./images/eight.png";
import mission from "./images/mission.png";
import { useState } from "react";
import Footer from "./Footer";
export default function Home() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log(window.scrollY);
      //  const isAtBottom =        window.innerHeight + window.scrollY >= document.documentElement.offsetHeight;
      const isAtBottom = window.scrollY > 10;
      setScrolled(isAtBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header scrolled={true} />
      <Banner />
      <Products />
      <Service />
      <Mission />
      <Footer />
    </>
  );
}

const Banner = (_) => {
  return (
    <>
      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <label className="banner_whitehead">Global</label>
        <label className="banner_whitehead">Entertainment</label>
        <label className="banner_yellowhead">Manufacturer</label>
        <label className="banner_contents">
          Global Entertainment Manufacturer is a premier manufacturer and
          supplier in the location-based entertainment industry. Providing
          quality, safety and innovative solutions to the trampoline park and
          indoor playground market...
        </label>
        <div>
          <button className="yellowbutton">Read More</button>
        </div>
      </div>
    </>
  );
};
const Products = (_) => {
  return (
    <>
      <div className="home_products">
        <label className="home_products_title">PRODUCTS</label>
        <div className="home_products_list">
          <ul>
            <li>
              <img src={product1} />
              <label>Trampoline</label>
            </li>
            <li>
              <img src={product2} />
              <label>Playground</label>
            </li>
            <li>
              <img src={product3} />
              <label>Role Play</label>
            </li>
            <li>
              <img src={product4} />
              <label>Projects</label>
            </li>
          </ul>
        </div>
        <button className="bluebutton">Order a Project</button>
      </div>
    </>
  );
};

const Service = (_) => {
  return (
    <>
      <div className="home_service">
        <div className="home_service_left">
          <label className="bluetitle">Services</label>
          <label className="blackheader">What we offer</label>
          <label className="paragraph">
            Our engineering, design, and art departments are second to none,
            they will create the right project to suit your needs, thrill and
            excite your guests with the best entertainment experience. Whether
            it is an indoor playground or a trampoline park, we customize your
            project with colors and artwork that will make it a unique
            attraction.
          </label>
          <label className="paragraph">
            With focus on operational excellence and customer satisfaction, GEM
            is dedicated to providing high quality and durable playgrounds that
            stand the test of time. All of our products are made with the finest
            materials and constructed with the utmost attention to detail,
            ensuring that they are safe and well built.
          </label>
          <label className="paragraph">
            Whether you are looking for one playground equipment to improve your
            business or an entire new facility, make Global Entertainment
            Manufacturer your partner.
          </label>
          <div>
            <button className="bluebutton">Read More</button>
          </div>
        </div>
        <div className="home_service_right">
          <div className="home_service_right_row">
            <div className="home_service_right_row_element">
              <img src={one} />
              <label>Top-quality material</label>
            </div>
            <div className="home_service_right_row_element">
              <img src={two} />
              <label>In-house Production</label>
            </div>
          </div>
          <div className="home_service_right_row">
            <div className="home_service_right_row_element">
              <img src={three} />
              <label>Turn-Key Projects</label>
            </div>
            <div className="home_service_right_row_element">
              <img src={four} />
              <label>Bespoke Design</label>
            </div>
          </div>
          <div className="home_service_right_row">
            <div className="home_service_right_row_element">
              <img src={five} />
              <label>Quality Control</label>
            </div>
            <div className="home_service_right_row_element">
              <img src={six} />
              <label>On-site Installation</label>
            </div>
          </div>
          <div className="home_service_right_row">
            <div className="home_service_right_row_element">
              <img src={seven} />
              <label>Support & Maintenance</label>
            </div>
            <div className="home_service_right_row_element">
              <img src={eight} />
              <label>Warranty</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Mission = (_) => {
  return (
    <>
      <div className="home_mission">
        <div className="home_mission_left">
          <img src={mission} />
        </div>
        <div className="home_mission_right">
          <label className="bluetitle">Priorities</label>
          <label className="blackheader">Our vision & mission</label>
          <label className="paragraph">
            Safety and trustworthiness are the ground for all that we do. We are
            committed to delivering high quality products and services to our
            customers so they keep their business earning year after year with
            GEM.
          </label>
          <label className="paragraph">
            We want to revolutionize the soft play industry by creating the most
            imaginative, interactive and safest playground environments in the
            world, and be your company of choice when it comes to a full-service
            manufacturing and design facility.
          </label>
        </div>
      </div>
      <div className="home_downloadbutton">
        <button className="bluebutton">Download E-Catalog</button>
      </div>
    </>
  );
};
