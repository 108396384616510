import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/legobig.png";
import { useNavigate } from "react-router-dom";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">LEGO AREA</label>
        <div className="singleprd_contents">
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Lego Area
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
            Our LEGO area playground is a designated space within an an indoor playground that features LEGO building blocks and other LEGO-related activities. It is a creative and engaging space where children can build, play, and explore using LEGO bricks.The LEGO area can vary in size, but they are usually smaller than the main play area and may be located in a separate designated space within the facility. Also, the LEGO area features a variety of LEGO building blocks, minifigures, and other LEGO-related activities. These may include building challenges, free play, and interactive displays.
            </label>
            
          </div>
        </div>
      </div>
    </>
  );
};
