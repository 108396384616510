import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/interactive.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">INTERACTIVE TRAMPOLINE</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Interactive Trampoline
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
            An interactive trampoline is a type of trampoline that features an interactive wall that users can bounce off of and interact with while jumping.
            </label>
            <label className="paragraph">
            Our interactive wall trampolines are developed in-house, and include various games or challenges that your guest’s can compete against each other, lights, and other features that allow users to interact with it while jumping.
            </label> 
          </div>
        </div>
      </div>
    </>
  );
};
