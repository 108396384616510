import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/basketball.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">BASKETBALL</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Basketball
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              Basketball trampoline, also known as slam dunk trampoline, is a
              variation of basketball that is played on a trampoline. In this
              game, players use the trampoline to perform high-flying dunks and
              other acrobatic moves that would be impossible on a regular
              basketball court.
            </label>
            <label className="paragraph">
              Our basketball trampoline includes a basketball backboard and rim,
              which are designed to withstand the force of players dunking the
              ball.
            </label>{" "}
            <label className="paragraph">
              For safety purposes, GEM basketball trampolines have padding
              around the frame and other areas to protect players from impact
              and safety nets or walls around the perimeter to prevent players
              or balls from bouncing out of the playing area.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
