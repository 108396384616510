import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import performancetr from "./images/climbingwall.png";
import { useNavigate } from "react-router-dom";
import SingleMenu from "./SingleMenu";

export default function Trampoline() {
  return (
    <>
      <Header scrolled={true} />
      <TrampolineC />
      <Footer />
    </>
  );
}
const TrampolineC = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="singleprd">
        <label className="home_products_title">CLIMBING WALL</label>
        <div className="singleprd_contents">
        <SingleMenu />
          <div className="singleprd_left">
            <img src={performancetr} />
          </div>

          <div className="singleprd_right">
            <label className="singleprd_right_title">Product Information</label>
            <label>
              <span>Category: </span>Trampoline
            </label>
            <label>
              <span>Name: </span>Climbing Wall
            </label>
            <label>
              <span>Details: </span>
            </label>
            <label className="paragraph">
              A climbing wall trampoline, is a combination of a climbing wall
              and a trampoline. This type of equipment allows users to climb up
              a wall while bouncing on a trampoline surface, adding an extra
              level of challenge and excitement to the climbing experience.
            </label>
            <label className="paragraph">
              GEM climbing wallS can come in various shapes and sizes, according
              to our customer’s needs, but it is made from a durable material
              like plywood, plastic, or fiberglass. The climbing wall can have
              various hand and footholds to help the user climb up to the top -
              all customizable.
            </label>{" "}
            <label className="paragraph">
              To ensure the safety of users, our climbing wall trampolines have
              padding around the frame and other areas to protect users from
              impact. They may also have safety nets or walls around the
              perimeter to prevent users from bouncing off the equipment.
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
