import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import service1 from "./images/service1.png";
import service2 from "./images/service2.png";
import service3 from "./images/service3.png";
import service4 from "./images/service4.png";
import service5 from "./images/service5.png";
import service6 from "./images/service6.png";
import service7 from "./images/service7.png";
import service8 from "./images/service8.png";
import service9 from "./images/service9.png";
export default function Service() {
  return (
    <>
      <Header scrolled={true} />
      <Services />
      <Footer />
    </>
  );
}
const Services = (_) => {
  return (
    <div className="services">
      <label className="home_products_title">SERVICES</label>
      <SingleService
        img={service1}
        title="TURN-KEY SERVICE"
        txt="We take care of every aspect of your project, from initial planning to final implementation, providing a fully functional and operational entertainment venue."
        left={true}
      />{" "}
      <SingleService
        img={service2}
        title="CUSTOM MADE DESIGN & ENGINEERING"
        txt="Our engineering, design, and art departments are second to none, they will advise you about the specific requirements of location and the facility, as well as design and space utilization. Let us create the right project to suit your needs."
        left={false}
      />{" "}
      <SingleService
        img={service3}
        title="TOP-QUALITY MATERIALS"
        txt="To guarantee a safety performance, the materials used are top-quality material, certified, non-toxic, very durable and in line with the industry standards."
        left={true}
      />
      <SingleService
        img={service4}
        title="IN-HOUSE PRODUCTION"
        txt="We produce the components in-house to guarantee consistent quality, great customer service, shorter delivery time for complete projects and spare parts, with an optimized price for the client."
        left={false}
      />
      <SingleService
        img={service5}
        title="QUALITY CONTROL"
        txt="All GEM equipment is 100% tested in our factory to ensure safety, quicker installation, perfect component fitting and high-quality product."
        left={true}
      />
      <SingleService
        img={service6}
        title="ALL DETAILS INCLUDED"
        txt="Our offer includes all the details that the customer expects, meaning all primary elements of the playground area, the platforms, safety nets, wall protection and also the specific details that make each project unique as colors, wall design and themes.GEM Projects are 100% true to the design approved.
"
        left={false}
      />
      <SingleService
        img={service7}
        title="ON-SITE INSTALLATION"
        txt="Our expert team of installers are an integral part behind delivering a product, they are knowledgeable about the product being installed and have the necessary tools and equipment to complete the job efficiently and safely."
        left={true}
      />
      <SingleService
        img={service8}
        title="SUPPORT & MAINTENANCE"
        txt="GEM support services provide the clients with access to technical experts and resources that can help them troubleshoot problems, and optimize the performance of their entertainment facility.Our services include technical assistance, repair services, routine inspections, cleaning and sanitation services. They help ensure the long-term viability and success of your operation.By providing ongoing assistance and ensuring that the facility is properly maintained, GEM customers can continue to offer a high-quality experience to their guests, while also minimizing downtime and repair costs."
        left={false}
      />
      <SingleService
        img={service9}
        title="WARRANTY"
        txt="We offer warranty to provide our customers with a level of protection and reassurance, knowing that the product they are purchasing is guaranteed to meet certain standards.Our warranty can vary from 1 till 5 years and cover defects in materials or workmanship, as well as malfunctions that occur during normal use.In order to allow our customer to keep earning with his attraction, GEM will change the specific part on its cost while the part is being tested or repaired."
        left={true}
      />
    </div>
  );
};
const SingleService = ({ img, title, txt, left }) => {
  return (
    <div className={left?"services_single services_single_lefts":"services_single services_single_rights"}>
      <div className="services_single_left">
        <label className="services_single_left_title">{title}</label>
        <label className="services_single_left_content">{txt}</label>
      </div>
      <div className="services_single_right">
        <img src={img} />
      </div>
    </div>
  );
};
