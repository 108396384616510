import React from 'react'
import logo from "./images/logo.png";
import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import linkedin from "./images/linkedin.png";
import bg from './images/footer.png'

export default function Footer() {
  return (
    <>
    <div className="footer" style={{backgroundImage:`url(${bg}), linear-gradient(90deg, #233a80, #00b2e3)`}}>
        <div className='footer_main'>
          <div className='footer_main_col1'>
            <div>
              <img className='footer_main_col1_logo' src={logo} />
            </div>
            <div className='footer_main_col1_social'>
              <img src={facebook} />
              <img src={instagram} />
              <img src={linkedin} />
            </div>
          </div>
          <div className='footer_main_col2'>
            <label className='footer_main_title'>LINKS</label>
            <ul>
              <li>Contact</li>
              <li>Home</li>
              <li>About</li>
              <li>Services</li>
              <li>Products</li>
            </ul>
          </div>
          <div className='footer_main_col3'>
            <label className='footer_main_title'>PRODUCTS</label>
            <ul>
              <li>Trampoline</li>
              <li>Playground</li>
              <li>Role play</li>
              <li>Projects</li>
            </ul>
          </div>
          <div className='footer_main_col4'>
            <label className='footer_main_title'>SERVICES</label>
            <ul>
              <li>Turn-key service</li>
              <li>Custom made design</li>
              <li>Top-quality materials</li>
              <li>In-house production</li>
            </ul>
          </div>
          <div className='footer_main_col5'>
            <label className='footer_main_title'>CONTACT US</label>
            <label className='footer_main_col5_label'>Sahab - 60 Street,</label>
            <label className='footer_main_col5_label'>Amman, Jordan</label>
            <label className='footer_main_col5_label'>Phone: +962797600316</label>
            <label className='footer_main_col5_label'>Email: info@gem-jo.com</label>
          </div>
        </div>
        <div className='footer_copyright'>
          <label>&#169;	2023-24</label>
        </div>
      </div></>
  )
}
