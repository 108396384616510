import React from "react";
import prd1 from "./images/prd1.png";
import prd2 from "./images/prd2.png";
import prd3 from "./images/prd3.png";
import prd4 from "./images/prd4.png";
import right from "./images/right.png";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
export default function Products() {
  return (
    <>
      <Header scrolled={true} />
      <Product />
      <Footer />
    </>
  );
}
const Product = (_) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="products">
        <label className="home_products_title">PRODUCTS</label>
        <div className="products_row">
          <div
            className="products_row_single"
            onClick={(e) => navigate("/Trampoline")}
          >
            <img src={prd1} />
            <div>
              <label>TRAMPOLINE</label>
              <img src={right} />
            </div>
          </div>
          <div
            className="products_row_single"
            onClick={(e) => navigate("/PlayGround")}
          >
            <img src={prd2} /> 
            <div>
              <label>PLAYGROUND</label>
              <img src={right} />
            </div>
          </div>
        </div>
        <div className="products_row">
          <div className="products_row_single">
            <img src={prd3} /> 
            <div>
              <label>ROLE PLAY</label> 
            </div>
          </div>
          <div className="products_row_single">
            <img src={prd4} /> 
            <div>
              <label>PROJECTS</label> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
