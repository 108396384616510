import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import location from "./images/location.png";
import email from "./images/email.png";
import call from "./images/call.png";
export default function Contact() {
  return (
    <>
      <Header scrolled={true} />
      <Contacts />
      <Footer />
    </>
  );
}

const Contacts = (_) => {
  return (
    <>
      <div className="contact">
        <label className="home_products_title">CONTACT US</label>
        <div className="contact_outer">
          <div className="contact_outer_left">
            <div className="contact_outer_left_single">
              <img src={location} />
              <div>
                <h3>Location</h3>
                <label>Sahab - 60 Street - Amman - Jordan</label>
              </div>
            </div>
            <div className="contact_outer_left_single">
              <img src={email} />
              <div>
                <h3>Email</h3>
                <label>info@gem-jo.com</label>
              </div>
            </div>
            <div className="contact_outer_left_single">
              <img src={call} />
              <div>
                <h3>Call</h3>
                <label>+962797600316</label>
              </div>
            </div>
            <div className="contact_outer_left_map">
              
            </div>
          </div>
          <div className="contact_outer_right">
            <div className="form_row">
              <div className="form_row_half">
                <label>Your Name</label>
                <input type="text" />
              </div>
              <div className="form_row_half">
                <label>Your Email</label>
                <input type="text" />
              </div>
            </div>
            <div className="form_row">
              <div className="form_row_full">
                <label>Phone Number</label>
                <input type="text" />
              </div>
            </div>{" "}
            <div className="form_row">
              <div className="form_row_full">
                <label>Subject</label>
                <input type="text" />
              </div>
            </div>{" "}
            <div className="form_row">
              <div className="form_row_full">
                <label>Message</label>
                <textarea rows={3}></textarea>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button className="bluebutton">Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};