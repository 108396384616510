import React from "react";
import { HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import Bills from "./Bills";
import Login from "./Login";
import Home from "./Home";
import "./style/App.css";
import "./style/Tab.css";
import "./style/Mobile.css";
import "./style/Animations.css";
import AboutUs from "./AboutUs";
import Service from "./Service";
import Products from "./Products";
import Contact from "./Contact";
import Trampoline from "./Trampoline";
import PerformanceTrampoline from "./PerformanceTrampoline";
import MarioJump from "./MarioJump";
import BasketBall from "./BasketBall";
import ClimbingWall from "./ClimbingWall";
import FoamPit from "./FoamPit";
import Ninja from "./Ninja";
import RotatingBeam from "./RotatingBeam";
import Interactive from "./Interactive";
import DodgeBall from "./DodgeBall"; 
import PlayGround from "./PlayGround";
import SoftPlay from "./SoftPlay";
import Toddler from "./Toddler";
import LegoArea from "./LegoArea";

export default function Navigation() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path="/"></Route>
          <Route element={<Bills />} path="/Bills"></Route>
          <Route element={<Home />} path="/Home"></Route>
          <Route element={<AboutUs />} path="/About"></Route>
          <Route element={<Service />} path="/Service"></Route>
          <Route element={<Products />} path="/Products"></Route>
          <Route element={<Contact />} path="/Contact"></Route>
          <Route element={<Trampoline />} path="/Trampoline"></Route>
          <Route
            element={<PerformanceTrampoline />}
            path="/PerformanceTrampoline"
          ></Route>
          <Route element={<MarioJump />} path="/MarioJump"></Route>
          <Route element={<BasketBall />} path="/BasketBall"></Route>
          <Route element={<ClimbingWall />} path="/ClimbingWall"></Route>
          <Route element={<FoamPit />} path="/FoamPit"></Route>
          <Route element={<Ninja />} path="/Ninja"></Route>
          <Route element={<RotatingBeam />} path="/RotatingBeam"></Route>
          <Route element={<Interactive />} path="/Interactive"></Route>
          <Route element={<DodgeBall />} path="/DodgeBall"></Route> 
          <Route element={<PlayGround />} path="/PlayGround"></Route> 
          <Route element={<SoftPlay />} path="/SoftPlay"></Route> 
          <Route element={<Toddler />} path="/Toddler"></Route> 
          <Route element={<LegoArea />} path="/LegoArea"></Route> 
        </Routes>
      </BrowserRouter>
    </>
  );
}
