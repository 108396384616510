import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "./images/logo.png";
import Menu from "./Menu";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
export default function Header({ scrolled }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  return (
    <div
      className={scrolled ? "headerclass headerclass_scroll" : "headerclass"}
    >
      <div className="headerlogopart"   onClick={(e) => dispatch({ type: "setMenu", payload: !menu })}>
        <AiOutlineMenu />
        <img
          src={logo}
        
        />
      </div>
      <Menu scrolled={scrolled} />
    </div>
    // <div className={scrolled ? "headerclass scroll" : "headerclass"}>
    //   <div className="headerclass_head">
    //     <img src={logo} />
    //   </div>
    //   <Menu />
    // </div>
  );
}
